import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import MenuItemList from "./menu_item_list";
import MenuItemModal from "./menu_item_modal";

function CatMenuList({
  direction,
  scrollId,
  products,
  language,
  sectionRefs,
  setscrollSpyElement,
  currentElementIndexInViewport,
  currency,
}) {
  const [selectedData, setSelectedData] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleSelectedData = (datac) => {
    setSelectedData(datac);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    setscrollSpyElement(currentElementIndexInViewport);
    // eslint-disable-next-line
  }, [currentElementIndexInViewport]);
  return (
    <>
      <Row
        className="mx-2 scroll-mt"
        id={`section-${scrollId}`}
        ref={sectionRefs[scrollId]}
        dir={direction}
      >
        {products?.map((item, index) => {
          return (
            <MenuItemList
              key={"menuitemlist-" + index}
              onSelect={handleSelectedData}
              product={item}
              language={language}
              currency={currency}
            />
          );
        })}
        {currency[0] === "SAR" ? (
          <Col xs={6} lg={3} className="px-1 text-center mb-3">
            <Card className="border-0 h-product-card">
              <Card.Img
                variant="top"
                src="https://awjftp.blob.core.windows.net/awjcontents/digimenuAllergens/Awani_Allergens.png"
              />
              <Card.Body>
                <Card.Title>
                  {language === "AR" ? "مسببات الحساسية" : "Allergens"}
                </Card.Title>
                <Card.Text className="lh-sm">
                  {language === "AR"
                    ? "يرجى ملاحظة أن الطعام المحضر هنا قد يحتوي على مواد مسببة للحساسية. لا تتردد في التحدث مع أحد أعضاء فريقنا إذا كنت بحاجة إلى معلومات بخصوص مسببات حساسية محددة."
                    : "Please note that the food prepared here may contain allergens. Feel free to speak with a member of our team if you need information regarding specific allergens."}
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                <div className="row justify-content-between pe-3 py-3"></div>
              </Card.Footer>
            </Card>
          </Col>
        ) : (
          ""
        )}
      </Row>
      {selectedData != null && (
        <MenuItemModal
          product={selectedData}
          show={showModal}
          handleClose={handleCloseModal}
          language={language}
          direction={direction}
          currency={currency}
        />
      )}
    </>
  );
}

export default CatMenuList;
